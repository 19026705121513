import React, { useState } from 'react';
import { Box, createStyles, makeStyles, MenuList, Popover, Typography } from '@material-ui/core';
import Spinner from '../../../../../se/components/Spinner';
import { useMutation } from '@apollo/client';
import { editColor } from '../../../../../graph/procedures';
import { InvertColors } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';

export const colorTypes: (string | null)[] = [
  '#7ED321',
  '#00A7F7',
  '#B057FF',
  '#FF5ABB',
  '#F55323',
  '#B29377',
  '#FFAC00',
  null,
];

//@ts-ignore
const colorTypesConst = colorTypes as const;
export type ColorTypes = typeof colorTypesConst[];

const ColorType = ({ procedureId, color }: { procedureId: number; color?: string }) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const popoverId = open ? 'simple-popover' : undefined;

  const [setColor] = useMutation(editColor);

  const openEditor = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const selectColorType = async (color: string | null) => {
    try {
      await setColor({ variables: { id: procedureId, color } });
    } catch (e) {
      console.log(e);
    } finally {
      handleClose();
    }
  };

  return (
    <div className={classes.wrapper}>
      <IconButton size="small" onClick={openEditor}>
        <InvertColors style={{ color: color || 'inherit' }} />
      </IconButton>
      {open && <Popover
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClick={e => e.stopPropagation()}
        onClose={handleClose}
        getContentAnchorEl={null}
        keepMounted
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {colorTypes ? (
          <Box px={2} py={2}>
            <Typography gutterBottom>Choose procedure color</Typography>
            <MenuList onClick={e => e.stopPropagation()}>
              {colorTypes.map((a: string | null) => (
                <div
                  key={a || 'remove'}
                  onClick={() => selectColorType(a)}
                  className={classes.colorSquare}
                  style={{
                    backgroundColor: a || 'transparent',
                    borderColor: color === a ? 'rgba(0, 0, 0, 0.08)' : 'transparent',
                  }}
                >
                  {a ? null : 'Remove'}
                </div>
              ))}
            </MenuList>
          </Box>
        ) : (
          <div className={classes.spinnerContainer}>
            <Spinner />
          </div>
        )}
      </Popover>}
    </div>
  );
};

const useStyles = makeStyles(theme =>
  createStyles({
    wrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    spinnerContainer: {
      padding: '1rem',
    },
    colorSquare: {
      width: '16rem',
      height: '2rem',
      margin: '0.5rem',
      borderRadius: '0.5rem',
      cursor: 'pointer',
      border: '2px solid transparent',
      '&:hover': {
        border: '2px solid black',
      },
    },
  })
);

export default ColorType;
