import React, { Fragment, useCallback, useRef } from 'react';
import { addMinutes, differenceInMinutes } from 'date-fns';
import { isCanceled, isCompleted, isNoShow } from '../shared/procedureUtils';
import Break from '../overview/Break';
import styled from 'styled-components';
import Condenser from '../overview/Condenser';

import { useOverflownChildren } from '../../../../../hooks/useOverflownChildren';

import { findIndex } from 'lodash/array';
import Procedure from '../overview/Procedure';
import { useScope } from '../../../../../hooks/useScope';

const ProcedureList = styled.div`
  overflow-y: auto;
  width: 100%;
  height: 100%;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const CondensableList = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
`;

export const Procedures = ({
  hospitalId,
  procedures,
  operationRooms,
  operationRoom,
  numberOfOperationRooms,
  date,
  canUseScheduleViewProcedure,
  isUnassigned,
  setEditProcedure,
  openProcedureForm,
  withRef,
  isKiosk,
  editableStaff,
  editableAnesthesiologist,
  showBedNumber,
  totalRoomNumber,
}) => {
  const scope: any = useScope();
  const createProcedureScheduleModule = scope?.hospital?.modules?.createProcedureSchedule;

  const handleProcedureClick = useCallback(
    (procedure: any) => {
      if (canUseScheduleViewProcedure) {
        if (createProcedureScheduleModule) {
          openProcedureForm(procedure, operationRooms, operationRoom, date);
        } else {
          setEditProcedure(procedure);
        }
      }
    },
    [
      canUseScheduleViewProcedure,
      createProcedureScheduleModule,
      openProcedureForm,
      operationRooms,
      operationRoom,
      date,
      setEditProcedure,
    ]
  );

  return (
    <ProcedureList ref={withRef}>
      {procedures.map((procedure, i, procedures) => {
        // Use procedure.id as key if available, fallback to index
        const key = procedure.id || i;
        const currentEntryEndTime = addMinutes(procedure.startTime, procedure.duration);
        const nextEntryStartTime = procedures[i + 1] ? procedures[i + 1].startTime : null;
        const breakDuration = differenceInMinutes(nextEntryStartTime, currentEntryEndTime);
        const isPatientIconVisible = numberOfOperationRooms < 5;

        return (
          <Fragment key={key}>
            <Procedure
              hospitalId={hospitalId}
              onClick={() => handleProcedureClick(procedure)}
              procedure={procedure}
              isUnassigned={isUnassigned}
              date={date}
              isPatientIconVisible={isPatientIconVisible}
              isKiosk={isKiosk}
              editableStaff={editableStaff}
              editableAnesthesiologist={editableAnesthesiologist}
              showBedNumber={showBedNumber}
              condensView={totalRoomNumber >= 8}
            />
            {procedures[i + 1] && breakDuration >= 30 && <Break duration={breakDuration} />}
          </Fragment>
        );
      })}
    </ProcedureList>
  );
};

export const CondensedProcedures = (
  ({ hospitalId, procedures, now, editableStaff, editableAnesthesiologist, ...rest }) => {
    const ref = useRef();
    const { overflow } = useOverflownChildren(ref, now);

    const firstRelevantIdx = findIndex(
      procedures,
      procedure => !isCompleted(procedure) && !isCanceled(procedure) && !isNoShow(procedure)
    );
    const upcomingProcedures = firstRelevantIdx > -1 ? procedures.slice(firstRelevantIdx) : procedures;

    return (
      <CondensableList>
        <Condenser count={Math.max(firstRelevantIdx, 0)} isTop={true} />
        <Procedures
          hospitalId={hospitalId}
          procedures={upcomingProcedures}
          {...rest}
          withRef={ref}
          editableStaff={editableStaff}
          editableAnesthesiologist={editableAnesthesiologist}
        />
        <Condenser count={overflow.length} />
      </CondensableList>
    );
  }
);

export default Procedures;
