import React from 'react';
import styled from 'styled-components';
import LinkButton from '../../../../se/components/LinkButton';
import responsive from '../../../../se/utilities/responsive';

const HelperActionWrapper = styled.div`
  position: absolute;
  transform: translateY(50%);
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  font-size: 0.75rem;

  span {
    background: ${props => props.theme.backgroundColor.string()};
    line-height: 2;
    padding: 0 1rem;

    small {
      opacity: 0.5;
    }
  }
  a {
    color: #4fa3f7; /* Lighter blue shade */
    text-decoration: none;

    &:hover {
      color: #3578c1; /* Slightly darker blue for hover */
    }
  }

  ${responsive.sm.andSmaller`
    display: none;
  `};
`;

const ButtonIcon = styled.i`
  font-size: 1rem;
`;

const refreshTablet = () => {
  window.location.reload();
};

const HelperAction = ({ WrapperComponent = HelperActionWrapper }) => (
  <>
    <WrapperComponent>
      <span>
        <small>Experiencing problems with the app?</small>{' '}
        <LinkButton onClick={refreshTablet}>
          Click to refresh <ButtonIcon className="material-icons">refresh</ButtonIcon>
        </LinkButton>
      </span>
      {/* <a href="https://ospitek3300.zendesk.com/hc/en-us/requests/new" target="_blank" rel="noopener noreferrer">
        Click here for support
      </a> */}
    </WrapperComponent>
  </>
);

export default HelperAction;
