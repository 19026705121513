import { selectRaw, selectList } from '../../selectors.js';
import { Allergy, hasAllergy, extractAllergies, extractAllergy } from '../allergy.js';
import { useQuestionnaireValueViaSelector } from '../../../components/entities/surgeonProcedures/predefinedForms/hooks';

interface AllergiesQuestionary {
  allergies: Allergy[];
  answered: boolean;
  emptyName: string;
}

export const useAllergies = (): AllergiesQuestionary => {
  const emptyName = 'NO KNOWN DRUG ALLERGIES';

  const q1 = useQuestionnaireValueViaSelector(selectRaw('allergies.q1'));
  const q1List = useQuestionnaireValueViaSelector(selectList('allergies.q1.yes.allergy_list'));
  const q2 = useQuestionnaireValueViaSelector(selectRaw(`allergies.q2`));
  const q3 = useQuestionnaireValueViaSelector(selectRaw(`allergies.q3`));

  const hasQ1 = hasAllergy('q1', q1);
  const hasQ2 = hasAllergy('q2', q2);
  const hasQ3 = hasAllergy('q3', q3);

  const answered = hasQ1 !== undefined && hasQ2 !== undefined && hasQ3 !== undefined;

  const allergies = [
    ...(extractAllergies(q1List ?? [])),
    ...[extractAllergy('q2', q2)] ?? [],
    ...[extractAllergy('q3', q3)] ?? [],
  ].filter((allergy?: Allergy): allergy is Allergy => !!allergy);

  return { allergies, answered, emptyName };
};
