import toString from './toString';
import { capitalize } from 'lodash';
import toHighlightsString from './toHighlightsString';

const toHighlightsStringNoDetails = toHighlightsString.with({
  Object: fields => {
    const visibleFields = fields.filter(field => field.child);

    return visibleFields.length === 0
      ? null
      : visibleFields.length === 1
      ? visibleFields[0].child
      : visibleFields
          .map(field => field.child)
          .map(c => {
            const rest = c!.startsWith('· ') ? c!.substr(2) : c!;
            return '· ' + rest;
          })
          .join('\n');
  },
  Checkboxes: (label, checkboxes) => {
    const visibleCheckboxes = checkboxes.filter(checkbox => checkbox.checked);

    return visibleCheckboxes.length === 0
      ? null
      : visibleCheckboxes.length === 1
      ? visibleCheckboxes[0].label
      : visibleCheckboxes.map(checkbox => `· ${checkbox.label.replace(/\?/g, '')}`).join('\n');
  },
  List: (label, children) => {
    const visibleChildren = children.filter(child => child);

    return visibleChildren.length === 0
      ? null
      : visibleChildren
          .map(c => {
            const rest = c!.startsWith('· ') ? c!.substr(2) : c!;
            return `* ${rest.replace(/\n· /g, ', ')}`;
          })
          .join('\n');
  },
  OneOf: (label, options, answer) => (answer ? `${answer.label}` : null),
});

export default toHighlightsStringNoDetails;
