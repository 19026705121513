import React, { useEffect, useMemo, useState } from 'react';
import { Box, createStyles, makeStyles, MenuItem, MenuList, Popover, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import Spinner from '../../../../../se/components/Spinner';
import { useMutation } from '@apollo/client';
import { editProcedureAnesthesiaType } from '../../../../../graph/procedures';

export const anesthesiaTypes: string[] = [
  'GEN',
  'MAC',
  'SED',
  'SAB',
  'CLE',
  'ARM - C-ARM',
  'C - Choice',
  'CO - Combo',
  'CS - Conscious Sedation',
  'E - Epidural',
  'EQ - Equipment Needed',
  'IG - General Anesthesia',
  'GB - General w/Block',
  'GC - General w/C-Arm',
  'GM - General w/Marking',
  'GV - General w/Vanco',
  'IB - Interscalene Block',
  'IR - IV Regional',
  'I - IV Sedation',
  'L- Local',
  'M - MAC',
  'MCB - MAC W/Block',
  'IMSD - MAC W/SEDATION',
  'MD - MD/RN Sedation',
  'MIN - Mini C Arm',
  'IN - None',
  'OVR - Overnight',
  'PND - Pending',
  'P&A - Popliteal & Adductor',
  'PFB - Popliteal Fossa Bloc',
  'RB - Retrobulbar Block',
  'S - Spinal',
  'EAR - T PLAST',
  'TVA - TIVA',
  'T - Topical',
];

//@ts-ignore
const anesthesiaTypesConst = anesthesiaTypes as const;
export type AnesthesiaTypes = typeof anesthesiaTypesConst[number];

const formatAnesthesiaType = (value?: string) => {
  const matches = value?.match(/^(\S+)\s*-\s*.+$/);
  const group1 = matches?.[1];

  if (group1) return group1;

  return anesthesiaTypes.includes((value || '').toUpperCase())
    ? value?.toUpperCase()
    : value
    ? value.split(' ').reduce((acc, curr) => acc + curr[0], '')
    : '';
};

const AnesthesiaType = ({
  procedureId,
  editableAnesthesiologist,
  anesthesiaType,
}: {
  procedureId: number;
  editableAnesthesiologist?: boolean;
  anesthesiaType?: string;
}) => {
  const classes = useStyles();

  const searchEl = React.useRef<HTMLInputElement | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const popoverId = open ? 'simple-popover' : undefined;

  useEffect(() => {
    if (open) {
      searchEl.current?.focus();
    } else {
      setSearch('');
    }
  }, [open]);

  const [search, setSearch] = useState('');

  const handleSearchUpdate = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.currentTarget.value);
  };

  const openEditor = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    editableAnesthesiologist && setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const filteredAnesthesiaTypes = useMemo(() => {
    const chunks = search.toLowerCase().split(/\s+/);
    return (anesthesiaTypes || []).filter((a: string) => {
      const t = a.toLowerCase();
      return chunks.every(chunk => t.indexOf(chunk) >= 0);
    });
  }, [search]);

  const [setAnesthesiaType] = useMutation(editProcedureAnesthesiaType);

  const selectAnesthesiaType = async (anesthesiaType: string) => {
    try {
      await setAnesthesiaType({ variables: { id: procedureId, anesthesiaType } });
    } catch (e) {
      console.log(e);
    } finally {
    }
  };

  return (
    <>
      <Box className={classes.editLabel} onClick={openEditor}>
        {formatAnesthesiaType(anesthesiaType)}
      </Box>
      {open && <Popover
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClick={e => e.stopPropagation()}
        onClose={handleClose}
        getContentAnchorEl={null}
        keepMounted
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {anesthesiaTypes ? (
          <Box className={classes.root}>
            <TextField
              size="small"
              variant="outlined"
              value={search}
              onChange={handleSearchUpdate}
              inputRef={searchEl}
              InputProps={{
                startAdornment: <SearchIcon fontSize="small" className={classes.searchIcon} />,
                autoCapitalize: 'off',
                autoComplete: 'off',
                autoCorrect: 'off',
                classes: {
                  root: classes.inputRoot,
                  inputAdornedStart: classes.inputAdornedStart,
                },
              }}
              classes={{
                root: classes.textFieldRoot,
              }}
            />
            <MenuList onClick={e => e.stopPropagation()} className={classes.menu}>
              {filteredAnesthesiaTypes.map((a: string) => (
                <MenuItem key={a} onClick={() => selectAnesthesiaType(a)}>
                  {a}
                </MenuItem>
              ))}
            </MenuList>
          </Box>
        ) : (
          <div className={classes.spinnerContainer}>
            <Spinner />
          </div>
        )}
      </Popover>}
    </>
  );
};

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      maxHeight: '38rem',
      overflow: 'hidden',
    },
    menu: {
      maxHeight: '28rem',
      overflowY: 'scroll',
    },
    textFieldRoot: {
      margin: '1rem',
      marginBottom: 0,
      padding: 0,
    },
    inputRoot: {
      width: '14rem',
      fontSize: '0.8125em',
      padding: '0.25em',
    },
    searchIcon: {
      marginLeft: '0.25em',
      marginRight: '0.25em',
    },
    inputAdornedStart: {
      paddingLeft: 0,
    },
    spinnerContainer: {
      padding: '1rem',
    },
    editLabel: {
      fontSize: '0.857em',
      color: theme.palette.primary.main,
      marginLeft: '0.5em',
    },
  })
);

export default AnesthesiaType;
