import React, { FC, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { Aggregation, AggregationContext } from '../Aggregation';
import Box from '@material-ui/core/Box';
import Header from '../components/Header';
import Divider from '@material-ui/core/Divider';
import TabNavigation from '../../sensor/TabNavigation';
import { Route, Switch } from 'react-router-dom';
import ORUtilizationAnalytics from '../ORUtilizationAnalytics';
import PhysicianUtilizationAnalytics from '../PhysicianUtilizationAnalytics';
import GeneralAnalytics from '../GeneralAnalytics';
import { makeStyles } from '@material-ui/core/styles';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import Filters from '../../analytics/Filters';

interface AggregationPickerProps {
  value: Aggregation;
  onChange: (value: Aggregation) => void;
}

const AggregationPicker = ({ value, onChange }: AggregationPickerProps) => {
  const handleAggregationChange = (_: React.MouseEvent<HTMLElement>, value: any) => {
    const parsed = Aggregation.safeParse(value);
    onChange(parsed.success ? parsed.data : 'average');
  };

  return (
    <Box display="flex" alignItems="center" mr={2}>
      <ToggleButtonGroup size="small" value={value} exclusive onChange={handleAggregationChange}>
        <ToggleButton value="average">Average</ToggleButton>
        <ToggleButton value="median">Median</ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
};

const tabs = match => [
  {
    id: 'general',
    name: 'General',
    pathname: match.url,
  },
  {
    id: 'operating-room',
    name: 'Analysis by OR',
    pathname: `${match.url}/operating-room`,
  },
  {
    id: 'physician',
    name: 'Analysis by Physician',
    pathname: `${match.url}/physician`,
  },
];

const AnalyticsV2: FC<{ filter: any; setFilter: any; isBusinessManager: boolean } & RouteComponentProps> = ({
  filter,
  setFilter,
  isBusinessManager,
  location,
  match,
}) => {
  const classes = useStyles();
  const shouldHideInputs = location.pathname.includes(`/physician`);

  const initialAggregation = useMemo(() => {
    const aggregation = localStorage.getItem('analytics-aggregation');
    const parsed = Aggregation.safeParse(aggregation);
    return parsed.success ? parsed.data : 'average';
  }, []);

  const [aggregation, setAggregation] = useState<Aggregation>(initialAggregation);

  const handleAggregationChange = (value: Aggregation) => {
    setAggregation(value);
    localStorage.setItem('analytics-aggregation', value);
  };

  return (
    <Box className={classes.root}>
      <Header filter={filter} />
      <Divider />
      <Box className={classes.toolBar}>
        <Box flex={1} className={classes.tabs}>
          <TabNavigation tabs={tabs(match)} location={location} />
        </Box>
        <Box className={classes.buttonGroup}>
          <Switch>
            <Route
              path={`${match.path}/operating-room`}
              render={() => <AggregationPicker value={aggregation} onChange={handleAggregationChange} />}
            />
            <Route
              path={`${match.path}/physician`}
              render={() => <AggregationPicker value={aggregation} onChange={handleAggregationChange} />}
            />
          </Switch>
        </Box>
        <Box flex={1} className={classes.filters}>
          <Filters
            onChange={setFilter}
            value={filter}
            reload={false}
            dontReloadOn={['operationRooms']}
            // showOperationRoomsSelectInput
            // hideSpecialitySelectInput={false}
          />
        </Box>
      </Box>

      <AggregationContext.Provider value={aggregation}>
        <Switch>
          <Route
            path={`${match.path}/operating-room`}
            render={() => <ORUtilizationAnalytics filter={filter} setFilter={setFilter} />}
          />
          <Route
            path={`${match.path}/physician`}
            render={() => <PhysicianUtilizationAnalytics filter={filter} setFilter={setFilter} />}
          />
          <Route
            path={`*`}
            render={() => (
              <GeneralAnalytics filter={filter} setFilter={setFilter} isBusinessManager={isBusinessManager} />
            )}
          />
        </Switch>
      </AggregationContext.Provider>
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'stretch',
    },
  },
  loading: {
    position: 'absolute',
    margin: 'auto',
  },
  actions: {
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  tabs: {
    marginLeft: -theme.spacing(2),
    marginRight: -theme.spacing(2),
  },
  buttonGroup: {
    '&:empty': {
      display: 'none',
    },
    [theme.breakpoints.down('md')]: {
      order: 2,
    },
  },
  filters: {
    [theme.breakpoints.down('md')]: {
      order: 1,
    },
  },
}));

export default AnalyticsV2;
