import React, { Dispatch, FC, Fragment, SetStateAction, useEffect, useState } from 'react';
import Booking from '../../../../../types/Booking';
import TimeFrame from './TimeFrame';
import { alpha, Box, makeStyles, Theme, Typography } from '@material-ui/core';
import Procedure, { TurnoverChip } from './Procedure';
import AnesthesiologistProcedure from '../anesthesiologist/AnesthesiologistProcedure';
import { Procedure as ProcedureT } from '../../../../../types/Procedure';
import styled from 'styled-components';
import { Room } from '../../../../../types/Room';
import responsiveStyles from '../../../../../se/utilities/responsive';
import { workingHours } from '../../../../entities/schedule/util/dummyData';
import TimeRangeInput from '../staff/TimeRangeInput';
import {
  getDurationFrom,
  getDurationFromTime,
  getEndTime,
  getHour,
  hourToTime,
  Time,
  timeToHour,
} from '../../../../entities/schedule/util/time';
import { useScope } from '../../../../../hooks/useScope';

const responsive = responsiveStyles as any;

const Column = styled.div<{ isKiosk?: boolean; current?: any }>`
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  margin: 0 0.05em;

  position: relative;

  :after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
  }

  ${props =>
    props.isKiosk
      ? `
      display: flex;
      flex-direction: column;
      min-height: 0;
    `
      : null}

  ${responsive.md.andSmaller`
    margin: 0;
    padding: 0;
    overflow: initial;
    flex: 1 0 auto;
    min-height: auto;

    scroll-snap-align: center;

    ${(props: any) => (props.current ? null : 'display: none;')}
  `};
`;

type ProcedureSlotBox = {
  from?: number | null;
  to?: number | null;
  clicked?: boolean;
  startCreating?: boolean;
  endCreating?: boolean;
};

const OpenBlockColor = '#33FF3F';
const HospitalBlockColor = '#33F0FF';

const OperatingRoomProcedures: FC<{
  operationRooms: Room[];
  index?: number;
  room: Room;
  currentOR?: string;
  isKiosk?: boolean;
  canUseScheduleViewProcedure?: boolean;
  isFrontDesk?: boolean;
  isPowerUser?: boolean;
  showOverlay?: boolean;
  editMode?: boolean;
  showBedNumber?: boolean;
  setEditProcedure?: Dispatch<SetStateAction<ProcedureT | null>>;
  date: Date;
  dayStart: Date;
  dayEnd: Date;
  openProcedureForm?: (
    procedure: ProcedureT | undefined | null,
    operationRooms: Room[],
    room: Room,
    date: Date,
    timeRange?: {
      startTime: Time;
      duration: number;
    }
  ) => void;
  startTime: Time;
  duration: number | null | undefined;
  setStartTime?: (val: Time) => void;
  setDuration?: (val: number | null | undefined) => void;
  anesthesiologistSchedule?: boolean;
}> = ({
  operationRooms,
  index,
  room,
  currentOR,
  isKiosk,
  canUseScheduleViewProcedure,
  showOverlay,
  editMode,
  showBedNumber,
  setEditProcedure,
  date,
  dayStart,
  dayEnd,
  openProcedureForm,
  startTime,
  duration,
  setStartTime,
  setDuration,
  anesthesiologistSchedule,
}) => {
  const scope = useScope();
  const createProcedureScheduleModule = scope?.hospital?.modules?.createProcedureSchedule;

  const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;

  const classes = useStyles();
  const [procedureSlot, setProcedureSlot] = useState<ProcedureSlotBox | null>(null);

  useEffect(() => {
    if (startTime && !procedureSlot?.startCreating) {
      if (timeToHour(startTime) !== procedureSlot?.from) {
        setProcedureSlot(prev => ({ ...prev, from: timeToHour(startTime), endCreating: true }));
      }
    }
  }, [startTime, procedureSlot?.from, procedureSlot?.startCreating]);

  useEffect(() => {
    if (duration) {
      if (getEndTime(startTime, duration) !== procedureSlot?.to && !procedureSlot?.startCreating) {
        setProcedureSlot(prev => ({ ...prev, to: timeToHour(getEndTime(startTime, duration)), endCreating: true }));
      }
    }
  }, [startTime, duration, procedureSlot?.to, procedureSlot?.startCreating]);

  const procedureClickHandler = (procedure: ProcedureT | null) => () => {
    if (canUseScheduleViewProcedure && setEditProcedure) {
      createProcedureScheduleModule
        ? openProcedureForm?.(procedure, operationRooms, room, date)
        : setEditProcedure(procedure);
    }
  };

  const timeRange = procedureSlot?.to
    ? `${hourToTime(procedureSlot?.from)}-${hourToTime(procedureSlot?.to)}`
    : `${hourToTime(procedureSlot?.from)}-`;

  const onInputChange = async (from: string | null, to: string | null, isValid: boolean, isCompleted: boolean) => {
    if (isCompleted || isValid) {
      if (isCompleted && isValid) {
        const timeRange = {
          startTime: from,
          duration: Math.round(getDurationFromTime(from, to) ?? 0),
        };
        openProcedureForm?.(undefined, operationRooms, room, date, timeRange);
      } else {
        setProcedureSlot(prev => ({ ...prev, from: timeToHour(from), to: timeToHour(to) }));
      }
    }
  };

  return (
    <Column
      isKiosk={isKiosk}
      key={room?.id || room?.name}
      id={room?.name}
      current={currentOR === room?.name || (!currentOR && index === 0) ? 'true' : null}
      onMouseDown={event => {
        if (createProcedureScheduleModule && !isTouchDevice) {
          const bounds = event.currentTarget.getBoundingClientRect();
          const height = event.currentTarget.clientHeight;
          const top = event.clientY - bounds.top;
          const fromHour = getHour(top, height);
          setProcedureSlot({ from: fromHour, startCreating: true });
          setStartTime?.(hourToTime(fromHour));
        }
      }}
      onMouseMove={event => {
        if (procedureSlot && !procedureSlot?.clicked && !procedureSlot?.endCreating) {
          const bounds = event.currentTarget.getBoundingClientRect();
          const height = event.currentTarget.clientHeight;
          const top = event.clientY - bounds.top;
          const toHour = getHour(top, height);
          if ((toHour || 0) > (procedureSlot?.from || 0)) {
            setProcedureSlot(prev => ({ ...prev, to: toHour }));
            setDuration?.(getDurationFrom(procedureSlot?.from, toHour));
          }
        }
      }}
      onMouseUp={_ => {
        if (createProcedureScheduleModule) {
          if (procedureSlot?.to) {
            setProcedureSlot(prev => (openProcedureForm ? null : { ...prev, startCreating: false, endCreating: true }));
            const timeRange = {
              startTime: hourToTime(procedureSlot?.from),
              duration: Math.round(getDurationFrom(procedureSlot?.from, procedureSlot?.to) ?? 0),
            };
            openProcedureForm?.(undefined, operationRooms, room, date, timeRange);
          }
        }
      }}
      onMouseOut={_ => {
        if (openProcedureForm) {
          setProcedureSlot(null);
        }
      }}
    >
      <Box className={classes.procedures}>
        {procedureSlot && !isTouchDevice && (
          <TimeFrame
            from={procedureSlot?.from || workingHours.start}
            to={procedureSlot?.to || workingHours.start}
            style={{
              pointerEvents: 'none',
              userSelect: 'none',
              backgroundColor: '#00A7F7',
              borderBottom: `1px solid #00A7F7`,
            }}
            onClick={() => openProcedureForm?.(undefined, operationRooms, room, date)}
          >
            <Box display="flex" flexDirection="column" m={1}>
              <>
                {!procedureSlot?.clicked && (
                  <Box className={classes.timeInput} style={{ color: 'white' }}>
                    {timeRange}
                  </Box>
                )}
                {procedureSlot?.clicked && (
                  <TimeRangeInput
                    defaultValue={timeRange}
                    onInputChange={onInputChange}
                    className={classes.timeInput}
                  />
                )}
              </>
            </Box>
          </TimeFrame>
        )}
        {showOverlay &&
          !isTouchDevice &&
          (room?.bookings || []).map((booking: Booking) => {
            const blockColor =
              booking?.physician?.color || (booking?.organization?.name ? HospitalBlockColor : OpenBlockColor);
            return (
              <TimeFrame
                key={booking.id}
                from={timeToHour(booking.startTime) || 0}
                to={timeToHour(booking.endTime) || 0}
                style={{
                  backgroundColor: `${blockColor}40`,
                  borderBottom: `1px solid ${blockColor}`,
                }}
                onClick={() => openProcedureForm?.(undefined, operationRooms, room, date)}
              >
                <Box display="flex" flexDirection="column" m={1} style={{ userSelect: 'none' }}>
                  <Typography variant="body2" style={{ color: blockColor }}>
                    {booking?.physician?.name || booking?.organization?.name || 'Open'}
                  </Typography>
                  <Typography variant="body2" style={{ color: blockColor }}>
                    {`${booking?.startTime}-${booking?.endTime}`}
                  </Typography>
                </Box>
              </TimeFrame>
            );
          })}
        {room?.procedures.map((procedure, j) => {
          console.debug('procedure', procedure);

          return !procedure.isCanceled ? (
            <Fragment key={procedure.id || `procedure-${j}`}>
              {!!j && (
                <TurnoverChip
                  startTimeCurrentProcedure={procedure?.startTime}
                  startTimePrevProcedure={room?.procedures?.[j - 1]?.startTime}
                  durationPrevProcedure={room?.procedures?.[j - 1]?.duration}
                  dayStart={dayStart}
                  dayEnd={dayEnd}
                  orOutAt={room?.procedures?.[j - 1]?.patient?.events?.orOutAt}
                  orInAt={procedure?.patient?.events?.orAt}
                />
              )}
              {/* This procedure */}
              {anesthesiologistSchedule ? (
                <AnesthesiologistProcedure
                  key={procedure.id || `anesthesiologist-procedure-${j}`}
                  dayStart={dayStart}
                  dayEnd={dayEnd}
                  procedure={procedure}
                  onClick={procedureClickHandler(procedure)}
                  isTouchDevice={true}
                  editMode={!!editMode}
                  showBedNumber={!!showBedNumber}
                  isKiosk={isKiosk}
                  date={date}
                />
              ) : (
                <Procedure
                  key={procedure.id || `regular-procedure-${j}`}
                  dayStart={dayStart}
                  dayEnd={dayEnd}
                  procedure={procedure}
                  onClick={procedureClickHandler(procedure)}
                  isTouchDevice={true}
                  editMode={!!editMode}
                  showBedNumber={!!showBedNumber}
                  isKiosk={isKiosk}
                  date={date}
                />
              )}
            </Fragment>
          ) : null;
        })}
      </Box>
    </Column>
  );
};

export const useStyles = makeStyles((theme: Theme) => ({
  timeInput: {
    backgroundColor: 'inherit',
    color: 'rgba(255, 255, 255, .5)',
    fontSize: '1rem',
    fontFamily:
      'Rubik,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
    fontWeight: 400,
    border: 'none',
    '&:focus': {
      outline: 'none',
    },
    userSelect: 'none',
  },
  procedures: {
    position: 'relative',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    backgroundColor: alpha(theme.palette.text.primary, 0.07),
  },
}));

export default OperatingRoomProcedures;
